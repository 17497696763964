'use client'

import ShareLiView from '@dg/common/components/Mobile/Common/Layerpopup/Share/ShareView/ShareLi/ShareLiView/ShareLiView'
import useToast from '@dg/common/lib/hooks/useToast'
import {
	shareSnsFunc
} from '@dg/common/lib/qoo10Common'
import {
	useCallback
} from 'react'
import {
	useTranslation
} from 'react-i18next'

type shareTitle = `Line` | `Facebook` | `Twitter` | `Link` | `Other`

interface ShareLiProps {
	title?: shareTitle;
}

const ShareLi = ({
	title = `Other`
}: ShareLiProps) => {
	const {
		t
	} = useTranslation()

	const toast = useToast()

	const shareCallbackFunc = useCallback((snsType: shareTitle) => {
		if (snsType === `Link`) {
			toast.open({
				id: `toast-alert`,
				text: t(`복사 되었습니다.`)
			})
		}
	}, [
		t,
		toast
	])

	const linkFunc = useCallback(() => new Promise((resolve) => {
		const shortUrl = shareSnsFunc({
			callbackFunc: shareCallbackFunc,
			type: `Link`
		})

		resolve(shortUrl)
	}), [
		shareCallbackFunc
	])

	const shareFunc = useCallback(async (snsType: shareTitle) => {
		const clipboardChk = new ClipboardItem({
			'text/plain': ``
		}) as unknown as {
			presentationStyle?: undefined | `unspecified`;
		}

		if (clipboardChk.presentationStyle === `unspecified` && snsType === `Link`) {
			const clipboardItem = new ClipboardItem({
				'text/plain': linkFunc().then((result) => new Promise((resolve) => {
					resolve(new Blob([
						result as string
					]))
				}))
			})

			await window.navigator.clipboard.write([
				clipboardItem
			])
		} else {
			shareSnsFunc({
				callbackFunc: shareCallbackFunc,
				type: snsType
			})
		}
	}, [
		linkFunc,
		shareCallbackFunc
	])

	const props = {
		shareFunc,
		title
	}

	return (
		<ShareLiView
			{...props}
		/>
	)
}

export default ShareLi
