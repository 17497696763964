'use client'

import AsyncBoundary from '@dg/common/components/AsyncBoundary'
import Layout from '@dg/common/components/Layout'
import Providers from '@dg/common/components/Providers'
import {
	appSwipeFunc, gaDimensionFunc
} from '@dg/common/lib/qoo10Common'
import '@dg/common/styles/common/common.css'
import {
	use as i18Use
} from 'i18next'
import PlayerWrap from 'live/components/PlayerWrap'
import 'live/styles/common/common.css'
import dynamic from 'next/dynamic'
import {
	usePathname
} from 'next/navigation'
import Script from 'next/script'
import {
	ReactNode, useCallback, useEffect, useMemo
} from 'react'
import {
	initReactI18next
} from 'react-i18next'

// i18n.changeLanguage(`en`)

i18Use(initReactI18next).init({
	fallbackLng: `ja`,
	interpolation: {
		escapeValue: false
	},
	lng: `ja`,
	resources: {}
})

const LazyScript = dynamic(() => import(`@dg/common/components/lazyScript`))

const RootLayout = ({
	children
}: {
	children: ReactNode;
}) => {
	const pathname = usePathname()

	const data = useMemo(() => ({
		delayFunc: null as unknown as ReturnType<typeof setInterval>
	}), [])

	const handleRouteChange = useCallback((url: string) => {
		gaDimensionFunc({
			url: url.startsWith(`https`) === false ? `${process.env.liveUrl}${url}` : url
		})
	}, [])

	useEffect(() => {
		appSwipeFunc(false)
	}, [])

	useEffect(() => {
		clearInterval(data.delayFunc)

		if (
			typeof window !== `undefined` &&
			typeof window.dataLayer !== `undefined` &&
			Array.isArray(window.dataLayer) === true &&
			typeof window.gtag !== `undefined`
		) {
			handleRouteChange(pathname || window.location.href)
		} else {
			data.delayFunc = setInterval(() => {
				if (
					typeof window !== `undefined` &&
					typeof window.dataLayer !== `undefined` &&
					Array.isArray(window.dataLayer) === true &&
					typeof window.gtag !== `undefined`
				) {
					clearInterval(data.delayFunc)

					handleRouteChange(pathname || window.location.href)
				}
			}, 500)
		}
	}, [
		data,
		handleRouteChange,
		pathname
	])

	return (
		<html
			lang="ja"
		>
			<head>
				<link
					href={process.env.liveUrl}
					rel="dns-prefetch"
				/>

				{/* App에서 사용하는 함수/값 */}
				<Script
					id="appScript"
				>
					{
						`
							window.MobileUtil = {
								// App CartCnt
								setCartCntFromApp: (count = 0) => {
									localStorage.setItem('appCartCnt', count)
								},
								// App QpostCnt
								setQpostCntFromApp: (count = 0) => {
									localStorage.setItem('appQpostCnt', count)
								}
							}

							// AppData to localStorage
							window.setAppDataToLocalStorage = (key, value) => {
								localStorage.setItem(key, value)
							}

							// GA
							const urlData = new URL(window.location.href)
							let _page_screen_name = "moweb"

							Array.from(urlData.pathname.replace(/\\/mobile\\/|\\/pc\\//gim, "").split("/")).map((item) => {
								_page_screen_name += ">"
								_page_screen_name += item

								return false
							})

							window.GetGAPageScreenName = () => _page_screen_name
						`
					}
				</Script>
			</head>

			<body>
				<Providers>
					<AsyncBoundary>
						<Layout>
							<PlayerWrap>
								{children}
							</PlayerWrap>
						</Layout>
					</AsyncBoundary>
				</Providers>

				{/* Gripcloud */}
				<Script
					id="gripScript"
					src="https://static.gripcloud.show/resource/js/gripcloud.min.js"
					strategy="beforeInteractive"
				/>

				<AsyncBoundary>
					<LazyScript />
				</AsyncBoundary>
			</body>
		</html>
	)
}

export default RootLayout
