'use client'

import Layerpopup from '@dg/common/components/Layerpopup'
import ShareLi from '@dg/common/components/Mobile/Common/Layerpopup/Share/ShareView/ShareLi'

const ShareView = () => (
	<Layerpopup
		id="layerpopup-share"
		wrapZIndex="z-200"
		bottom
		closeBtn
		disableDrag
		overflow
	>
		<ul
			className="horizon-14-6 -mx-4 flex"
		>
			<ShareLi
				title="Line"
			/>

			<ShareLi
				title="Twitter"
			/>

			<ShareLi
				title="Facebook"
			/>

			<ShareLi
				title="Other"
			/>

			<ShareLi
				title="Link"
			/>
		</ul>
	</Layerpopup>
)

export default ShareView
