'use client'

import Button from '@dg/common/components/Button'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import Image from 'next/image'
import {
	useTranslation
} from 'react-i18next'

type shareTitle = `Line` | `Facebook` | `Twitter` | `Link` | `Other`

interface ShareLiViewProps {
	shareFunc: (snsType: shareTitle) => void;
	title?: shareTitle;
}

const ShareLiView = ({
	title = `Other`, shareFunc
}: ShareLiViewProps) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	return (
		<li
			className="relative flex flex-1 justify-center"
		>
			<Button
				className="flex w-19 flex-col items-center space-y-3.5 text-xs"
				onClick={() => shareFunc(title)}
			>
				<Image
					alt={`Share To ${title}`}
					className="object-cover"
					height={60}
					onError={imageValidate.onError}
					src={`/images/mobile/common/${title.toLowerCase()}-share.png`}
					width={60}
				/>

				<span
					className="text-center"
				>
					{title === `Other` && t(`더보기`)}

					{title === `Link` && t(`링크 복사`)}

					{title !== `Other` && title !== `Link` && t(title)}
				</span>
			</Button>
		</li>
	)
}

export default ShareLiView
