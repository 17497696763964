'use client'

import Share from '@dg/common/components/Mobile/Common/Layerpopup/Share/Share'
import {
	addCookie, device
} from '@dg/common/lib/common'
import useLogin from '@dg/common/lib/hooks/useLogin'
import {
	gripPlayerOpenFunc
} from '@dg/common/lib/qoo10Common'
import {
	type liveApiProps
} from 'live/lib/mobile/api'
import {
	useSearchParams
} from 'next/navigation'
import {
	ReactNode, useEffect, useMemo
} from 'react'

const PlayerWrap = ({
	children
}: {
	children: ReactNode;
}) => {
	const searchParams = useSearchParams()
	const login = useLogin()

	const data = useMemo(() => ({
		delayFunc: null as unknown as ReturnType<typeof setTimeout>
	}), [])

	useEffect(() => {
		clearTimeout(data.delayFunc)
		data.delayFunc = setTimeout(() => {
			const dgCookieName = `liveplay=`

			const cookieChk = device().browser.app === undefined && document.cookie.match(dgCookieName) !== null

			if (searchParams.get(`play`) !== null || cookieChk === true) {
				const cookieSplit = document.cookie.split(dgCookieName)[1]?.split(`;`)[0].split(`_dg_`)

				const videoId = searchParams.get(`play`) ?? cookieSplit[0].split(`play=`)[1] ?? ``
				const linkId = searchParams.get(`id`) ?? cookieSplit[1].split(`id=`)[1] ?? ``
				const status = (searchParams.get(`status`) ?? cookieSplit[2].split(`status=`)[1] ?? `ERROR`) as liveApiProps[`vodStatus`]

				if (cookieChk === true) {
					addCookie(`liveplay=false`, 0)
				}

				gripPlayerOpenFunc({
					forcePlay: true,
					linkId,
					login: login.info,
					status,
					videoId
				})
			}
		}, 100)

		const cleanup = () => {
			document.querySelector(`#grip-player`)?.remove()
		}

		return cleanup
	}, [
		data,
		login.info,
		searchParams
	])

	return (
		<>
			{children}

			{/* GRIP PLAYER에서 사용 */}
			<Share />
		</>
	)
}

export default PlayerWrap
