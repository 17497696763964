'use client'

import ShareView from '@dg/common/components/Mobile/Common/Layerpopup/Share/ShareView/ShareView'
import useLayerpopup from '@dg/common/lib/hooks/useLayerpopup'
import {
	useEffect
} from 'react'

const Share = () => {
	const layerpopup = useLayerpopup()

	useEffect(() => {
		/* Grip Player 이벤트를 위한 함수 */
		window.toggleShareLayerpopup = (open = false) => {
			if (open === true) {
				layerpopup.open({
					id: `layerpopup-share`
				})
			} else {
				layerpopup.close(`layerpopup-share`)
			}
		}
	}, [
		layerpopup
	])

	return (
		<ShareView />
	)
}

export default Share
